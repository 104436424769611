import React from 'react'
import { graphql } from 'gatsby';
import SEO from 'components/seo';
import Layout from 'components/layout';
import Header from 'components/Header';
import Career from 'components/Sections/SSContact/Career';


const CareerPage = ({
  data: {markdownRemark: { frontmatter } },
  location,
}) => {
  const lang = location && location.pathname.split('/')[1];
  return (
    <Layout>
      <SEO
        lang={lang}
        title="Career"
      />
      <Header rightGradient lang={lang} />
      <Career page={frontmatter} />
    </Layout>
  )
}


export const pageQuery = graphql`
  query Career($lang: String!) {
    markdownRemark(frontmatter: { lang: { eq: $lang }, templateType: { eq: "contact-us"}}) {
      frontmatter {
        title
        label
        heading
        contact_info {
          address
          emails {
            email
          }
          phones {
            phone
          }
        }
      }
    }
  }
`

export default CareerPage;
